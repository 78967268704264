import { ReactNode } from "react"
import { LogoIcon } from "@/assets/icons"
import { Flex } from "@/components/common"
import styled from "styled-components"

const BoxWrapper = styled.div`
    max-width: 1000px;
    width: 100%;
    min-height: 737px;
    border-radius: 8px;
    box-shadow: 0px 38px 35px -25px ${p => p.theme.palette.violet500};
    background: ${p => p.theme.palette.paper};
    display: flex;
    align-content: center;
    justify-content: space-between;
`

const WorkSection = styled.div`
    @media (max-width: ${p => p.theme.breakpoints.md}px) {
        margin: 20px;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 50px;
    background: ${p => p.theme.palette.paper};
`
const InfoSection = styled.div`
    @media (max-width: ${p => p.theme.breakpoints.md}px) {
        display: none;
    }
    border-radius: 0px 8px 8px 0px;
    display: flex;
    max-width: 435px;
    width: 100%;
    background-image: url("/images/auth_background.svg");
`
const ContentSection = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`

export const AuthenticateBox = ({ children }: { children: ReactNode }) => {
    return (
        <Flex justify="center" align="center" fullW fullH>
            <BoxWrapper>
                <WorkSection>
                    <LogoIcon height={24} width={112} />
                    <ContentSection>{children}</ContentSection>
                </WorkSection>
                <InfoSection />
            </BoxWrapper>
        </Flex>
    )
}
