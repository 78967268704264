/* eslint-disable max-lines */
import { useEffect } from "react"
import { isNewTestVar } from "@/graphql/apollo/vars"
import {
    AuthenticateUserMutation,
    AuthenticateUserMutationVariables,
    CreateTestAdminMutation,
    CreateTestAdminMutationVariables,
    DeleteTestAdminMutation,
    DeleteTestAdminMutationVariables,
    DisableTestAdminMutation,
    DisableTestAdminMutationVariables,
    DuplicateTestAdminMutation,
    DuplicateTestAdminMutationVariables,
    EnableTestAdminMutation,
    EnableTestAdminMutationVariables,
    GetAllQuestionsAdminQuery,
    GetAllQuestionsAdminQueryVariables,
    GetAllTestsQuery,
    GetAllTestsQueryVariables,
    GetAllUsersAdminQuery,
    GetAllUsersAdminQueryVariables,
    GetSolutionQuery,
    GetSolutionQueryVariables,
    GetTestAdminQuery,
    GetTestAdminQueryVariables,
    GetTestQuery,
    GetTestQueryVariables,
    ImpersonateUserAdminMutation,
    ImpersonateUserAdminMutationVariables,
    PublishTestAdminMutation,
    PublishTestAdminMutationVariables,
    RecruiterSignUpMutation,
    RecruiterSignUpMutationVariables,
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
    SetPasswordMutation,
    SetPasswordMutationVariables,
    StarSolutionMutation,
    StarSolutionMutationVariables,
    TriggerPasswordResetAdminMutation,
    TriggerPasswordResetAdminMutationVariables,
    UpdateTestAdminMutation,
    UpdateTestAdminMutationVariables,
    useAuthenticateUserMutation as _useAuthenticateUserMutation,
    useCreateTestAdminMutation as _useCreateTestAdminMutation,
    useDeleteTestAdminMutation as _useDeleteTestAdminMutation,
    useDisableTestAdminMutation as _useDisableTestAdminMutation,
    useDuplicateTestAdminMutation as _useDuplicateTestAdminMutation,
    useEnableTestAdminMutation as _useEnableTestAdminMutation,
    useGetAllQuestionsAdminQuery as _useGetAllQuestionsAdminQuery,
    useGetAllTestsAdminQuery as _useGetAllTestsAdminQuery,
    useGetAllUsersAdminQuery as _useGetAllUsersAdminQuery,
    useGetSolutionQuery as _useGetSolutionQuery,
    useGetTestAdminQuery as _useGetTestAdminQuery,
    useGetTestQuery as _useGetTestQuery,
    useImpersonateUserAdminMutation as _useImpersonateUserAdminMutation,
    usePublishTestAdminMutation as _usePublishTestAdminMutation,
    useRecruiterSignUpMutation as _useRecruiterSignUpMutation,
    useResetPasswordMutation as _useResetPasswordMutation,
    useSetPasswordMutation as _useSetPasswordMutation,
    useStarSolutionMutation as _useStarSolutionMutation,
    useTriggerPasswordResetAdminMutation as _useTriggerPasswordResetAdminMutation,
    useUpdateTestAdminMutation as _useUpdateTestAdminMutation,
} from "@/graphql/gen"
import * as Apollo from "@apollo/client"
import { useReactiveVar } from "@apollo/client"
import { useRouter } from "next/router"

export const useGetSolutionQuery = (p?: Apollo.QueryHookOptions<GetSolutionQuery, GetSolutionQueryVariables>) => {
    const {
        query: { solutionId },
    } = useRouter()

    const uuid = Array.isArray(solutionId) ? solutionId[0] : solutionId

    return _useGetSolutionQuery({
        variables: { uuid },
        skip: uuid === undefined,
        ...p,
    })
}

export const useAuthenticateUserMutation = (
    p?: Apollo.MutationHookOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>
) => {
    return _useAuthenticateUserMutation(p)
}

export const useResetPasswordMutation = (
    p?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) => {
    return _useResetPasswordMutation(p)
}

export const useRecruiterSignUpMutation = (
    p?: Apollo.MutationHookOptions<RecruiterSignUpMutation, RecruiterSignUpMutationVariables>
) => {
    return _useRecruiterSignUpMutation(p)
}

export const useSetPasswordMutation = (
    p?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>
) => {
    return _useSetPasswordMutation(p)
}

export const useStarSolutionMutation = (
    p?: Apollo.MutationHookOptions<StarSolutionMutation, StarSolutionMutationVariables>
) => {
    return _useStarSolutionMutation(p)
}

export const useGetTestQuery = (p?: Apollo.QueryHookOptions<GetTestQuery, GetTestQueryVariables>) => {
    const {
        query: { id: idQuery },
    } = useRouter()

    const id = Array.isArray(idQuery) ? idQuery[0] : idQuery

    return _useGetTestQuery({
        variables: { id },
        skip: id === undefined,
        ...p,
    })
}

export const useClickOutsideWithIncluding = (
    reference: React.RefObject<HTMLDivElement>,
    included: React.RefObject<HTMLDivElement>,
    action: () => void
) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                !(
                    (reference.current && reference.current.contains(event.target as Node)) ||
                    (included.current && included.current.contains(event.target as Node))
                )
            ) {
                action()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reference])
}

export const useImpersonateUserAdminMutation = (
    p?: Apollo.MutationHookOptions<ImpersonateUserAdminMutation, ImpersonateUserAdminMutationVariables>
) => {
    return _useImpersonateUserAdminMutation(p)
}
export const useTriggerPasswordResetAdminMutation = (
    p?: Apollo.MutationHookOptions<TriggerPasswordResetAdminMutation, TriggerPasswordResetAdminMutationVariables>
) => {
    return _useTriggerPasswordResetAdminMutation(p)
}

export const useGetTestAdminQuery = (p?: Apollo.QueryHookOptions<GetTestAdminQuery, GetTestAdminQueryVariables>) => {
    const { query } = useRouter()
    const id = Array.isArray(query.id) ? query.id[0] : query.id
    const isNewTest = useReactiveVar(isNewTestVar)

    return _useGetTestAdminQuery({ ...p, skip: isNewTest, variables: { ...p?.variables, id } })
}

export const useGetAllTestsAdminQuery = (p?: Apollo.QueryHookOptions<GetAllTestsQuery, GetAllTestsQueryVariables>) => {
    return _useGetAllTestsAdminQuery(p)
}
export const useUpdateTestAdminMutation = (
    p?: Apollo.MutationHookOptions<UpdateTestAdminMutation, UpdateTestAdminMutationVariables>
) => {
    return _useUpdateTestAdminMutation(p)
}

export const useCreateTestAdminMutation = (
    p?: Apollo.MutationHookOptions<CreateTestAdminMutation, CreateTestAdminMutationVariables>
) => {
    return _useCreateTestAdminMutation(p)
}
export const usePublishTestAdminMutation = (
    p?: Apollo.MutationHookOptions<PublishTestAdminMutation, PublishTestAdminMutationVariables>
) => {
    return _usePublishTestAdminMutation(p)
}
export const useGetAllUsersAdminQuery = (
    p?: Apollo.QueryHookOptions<GetAllUsersAdminQuery, GetAllUsersAdminQueryVariables>
) => {
    return _useGetAllUsersAdminQuery(p)
}
export const useDeleteTestAdminMutation = (
    p?: Apollo.MutationHookOptions<DeleteTestAdminMutation, DeleteTestAdminMutationVariables>
) => {
    return _useDeleteTestAdminMutation(p)
}
export const useEnableTestAdminMutation = (
    p?: Apollo.MutationHookOptions<EnableTestAdminMutation, EnableTestAdminMutationVariables>
) => {
    return _useEnableTestAdminMutation(p)
}

export const useDuplicateTestAdminMutation = (
    p?: Apollo.MutationHookOptions<DuplicateTestAdminMutation, DuplicateTestAdminMutationVariables>
) => {
    return _useDuplicateTestAdminMutation(p)
}

export const useDisableTestAdminMutation = (
    p?: Apollo.MutationHookOptions<DisableTestAdminMutation, DisableTestAdminMutationVariables>
) => {
    return _useDisableTestAdminMutation(p)
}
export const useGetAllQuestionsAdminQuery = (
    p?: Apollo.QueryHookOptions<GetAllQuestionsAdminQuery, GetAllQuestionsAdminQueryVariables>
) => {
    return _useGetAllQuestionsAdminQuery(p)
}
